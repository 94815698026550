import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LoginForm from "./templates/encuesta-login"

const Login = ({ location }) => (
  <Layout location={location}>
    <SEO LoginForm="Formulario de Login" />
    <LoginForm />
  </Layout>
)

export default Login